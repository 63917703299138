// necessary set
import { observer } from 'mobx-react'
import { RenderRouters } from '@src/Routers'

/**
 *
 * @author cardo
 *
 * @description
 * cardo-home App
 * App 선언 : mobx observer set
 */
const App = observer(() => {
  return <div className="App">{RenderRouters()}</div>
})
export default App
