export type MenuItem = {
  url: string
  title: string
  target?: '_blank' | '_self'
  type: 'nav' | 'btn'
  className?: string
}

export const NavMenuList: MenuItem[] = [
  {
    url: '/Custody',
    title: '커스터디',
    type: 'nav',
  },
  {
    url: '/Solution',
    title: '솔루션',
    type: 'nav',
  },
  {
    url: '/Company',
    title: '회사 소개',
    type: 'nav',
  },
  {
    url: 'https://kdac.notion.site/MEDIA-1d56c0bcc7f44812868ea205eaa420a5',
    title: '미디어',
    target: '_blank',
    type: 'nav',
  },
  {
    url: '/Notice',
    title: '공지사항',
    type: 'nav',
  },
  {
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSfs9hUa8DHIaJROi50ST4iRPJ-TgIm8YOW5bAnAsPUOb77cCw/viewform',
    title: '문의하기',
    className: 'btn_contact',
    type: 'btn',
  },
  {
    url: '/main',
    title: '로그인',
    className: 'btn_login',
    type: 'btn',
  },
]
