// necessary set
// import React, { useState, useRef } from 'react'
import React, { useRef } from 'react'
import { observer } from 'mobx-react'

// need content
// import Loading from '../loading/Loading'
import Top from '@pages/layout/Top'
import Footer from '@pages/layout/Footer'
import MainContainer from '@src/pages/main/container/MainContainer'
import CustodyContainer from '@src/pages/custody/CustodyContainer'
import CompanyContainer from '@src/pages/company/CompanyContainer'
import NoticeContainer from '@src/pages/board/NoticeContainer'
import BoardView from '@src/pages/board/BoardView'
import SolutionContainer from '@pages/solution/SolutionContainer'

// Props type
import { IPageProps } from '@common/PropsType'

// need style
import '@pages/layout/layout.scss'

/**
 * @author cardo
 * @description
 * sto-user-front: ParallaxLayout
 * Paralla xLayout
 */
const ParallaxLayout: React.FC<IPageProps> = observer((props) => {
  // layout thema

  const parallaxLayout = useRef<HTMLDivElement | null>(null)

  const goTop = () => {
    parallaxLayout?.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
  }

  const PAGE_MAP: Record<string, () => React.ReactElement> = {
    Main: () => <MainContainer />,
    Custody: () => <CustodyContainer pageName={props.pageName} goTop={goTop} />,
    Solution: () => <SolutionContainer />,
    Company: () => <CompanyContainer />,
    Notice: () => <NoticeContainer />,
    BoardView: () => <BoardView />,
  }

  return (
    <div className="parallax-layout" ref={parallaxLayout}>
      <div className="go-up" onClick={goTop}></div>
      <div className="container">
        <Top pageName={props.pageName} subName={props.subName} goTop={goTop} />
        {PAGE_MAP[props.pageName]()}
        <Footer pageName={props.pageName} goTop={goTop} />
      </div>
    </div>
  )
})

export default ParallaxLayout
