import React from 'react'
import { observer } from 'mobx-react'
import { ElementProps } from '../props.type'
import { SectionFull } from '@component/section/section-full'
import { SectionPart } from '@component/section/section-part'
import { extractChildNodeToRecord } from '@component/content/content.util'
import './door.scss'

type DoorProps = ElementProps & {
  title?: string
  text?: string
}

export const SectionDoor: React.FC<DoorProps> = observer((props) => {
  const { childElements, otherChildren } = extractChildNodeToRecord(props.children, {
    title: props.title,
    text: props.text,
    button: null,
  })

  const hasButtonBox = () => childElements.button != null
  const getButtonBox = () => (hasButtonBox() ? <div className="door-btn-box">{childElements.button}</div> : null)
  //style={hasButtonBox() ? { marginBottom: '150px' } : undefined}
  return (
    <SectionFull classes={props.classes}>
      <SectionPart>
        <div className="section-content door">
          <p className="door-title">{childElements.title}</p>
          <p className={`door-text door-has-btn-${hasButtonBox()}`}>{childElements.text}</p>
          {getButtonBox()}
          {otherChildren}
        </div>
      </SectionPart>
    </SectionFull>
  )
})
