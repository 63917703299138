// necessary set
import React, { Fragment } from 'react'
import { observer } from 'mobx-react'

// need content
// Props type
import { ITableProps } from '@common/PropsType'

// need style

/**
 *
 * @author sto
 *
 * @description
 * sto-admin-front: BoardTd
 * 기본 BoardTd
 */
const BoardTd: React.FC<ITableProps> = observer((props) => {
  // console.log(props)
  const view = (id: any, url: any) => {
    // console.log(id)
    // console.log(url)
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer')
    } else {
      window.location.href = '/BoardView/' + id
    }
  }
  return (
    <Fragment>
      <div className="tb-txt">
        {props?.titleTxt &&
          props.titleTxt.map((data, i) => (
            <span
              key={`titleTxt_${i}`}
              style={{ width: `${data.width}%` }}
              onClick={
                props?.pageNumber
                  ? () => view(props?.pageNumber && props.pageNumber, props?.url && props.url)
                  : undefined
              }
            >
              {data.tag !== undefined ? <em style={{ backgroundColor: data.tag }}>{data.text}</em> : data.text}
            </span>
          ))}
      </div>
    </Fragment>
  )
})

export default BoardTd
