import React from 'react'
import { observer } from 'mobx-react'

type SectionFullProps = {
  id?: string
  height?: number
  ref?: React.MutableRefObject<any>
  children?: React.ReactNode
}

export const SectionFullHeader: React.FC<SectionFullProps> = observer((props) => {
  let height
  if (props.height != null && !isNaN(Number(props.height))) {
    height = props.height + 'px'
  }
  return (
    <React.Fragment>
      <div id={props.id} ref={props.ref} className="section-full section-full-sub-bg" style={{ height }}>
        <div
          className="section-part"
          style={{ '--sectionWidth': '1280px', justifyContent: 'center' } as React.CSSProperties}
        >
          {props.children}
        </div>
      </div>
    </React.Fragment>
  )
})
