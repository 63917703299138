// necessary set
import React from 'react'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

// Props type
import { IPageProps } from '@common/PropsType'

// need style
import '@pages/layout/top.scss'
import { NavMenuList } from './menu'

interface Props extends IPageProps {
  goTop: () => void
}

/**
 *
 * @author cardo
 *
 * @description
 * cardo-home : top
 * 기본 top
 */
const Top: React.FC<Props> = observer((props) => {
  const getMenuList = (menuType: 'menu' | 'hamburger') => {
    if (menuType === 'hamburger') {
      return (
        <ul className="menu__box">
          <li>
            <a className="menu__item" href="/">
              Home
            </a>
          </li>
          {NavMenuList.map((menu) => (
            <li key={menu.url}>
              <a
                className="menu__item"
                href={menu.url}
                target={menu.type}
                rel={menu.target === '_blank' || menu.type === 'btn' ? 'noreferrer' : undefined}
              >
                {menu.title}
              </a>
            </li>
          ))}
        </ul>
      )
    }

    return (
      <div className="top-menu">
        {NavMenuList.map((menu) => {
          if (menu.type === 'btn') {
            return (
              <div key={menu.url} className="top-btn">
                <a href={menu.url} className={menu.className} target={menu.target ?? '_blank'} rel="noreferrer">
                  {menu.title}
                </a>
              </div>
            )
          } //
          else {
            return (
              <div key={menu.url}>
                <NavLink
                  to={menu.url}
                  target={menu.target}
                  className={({ isActive }) => [isActive ? 'active' : ''].join(' ')}
                  onClick={() => props.goTop()}
                >
                  {menu.title}
                </NavLink>
              </div>
            )
          }
        })}
      </div>
    )
  }

  return (
    <header>
      <div className="top-content">
        <h1 className="logo">
          <NavLink to="/" onClick={() => props.goTop()}>
            <span className="top-logo"></span>
          </NavLink>
        </h1>
        {getMenuList('menu')}

        <div className="hamburger-menu">
          <input id="menu__toggle" type="checkbox" />
          <label className="menu__btn" htmlFor="menu__toggle">
            <span></span>
          </label>

          {getMenuList('hamburger')}
        </div>
      </div>
    </header>
  )
})

export default Top
