import { ReactElement, ReactNode } from 'react'

type ExtractedElement = {
  childElements: Record<string, ReactNode>
  otherChildren: ReactNode[]
}

const isReactElement = (node: ReactNode): node is ReactElement => {
  return node != null && typeof node == 'object' && 'key' in node && 'props' in node && 'type' in node
}

export const extractChildNodeToRecord = (
  children: ReactNode,
  childElements: Record<string, ReactNode> = {},
): ExtractedElement => {
  const elements = {
    childElements: childElements,
    otherChildren: [] as ReactNode[],
  } as ExtractedElement

  const extract = (node: ReactElement) => {
    if (node.key != null) {
      elements.childElements[node.key] = node
    } //
    else if ('id' in node && node.id != null) {
      elements.childElements[node.id as string] = node
    } //
    else if ('id' in node.props) {
      elements.childElements[node.props.id] = node
    } //
    else {
      elements.otherChildren.push(node)
    }
  }

  if (Array.isArray(children)) {
    for (const node of children) {
      extract(node)
    }
  } //
  else if (isReactElement(children)) {
    extract(children)
  } //
  else {
    elements.otherChildren.push(children)
  }

  return elements
}
