// necessary set
import { FC, Fragment } from 'react'
import { observer } from 'mobx-react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// need content
// need tool or method
// Props type
// Faram type
// need style
import './solution.scss'
import { SectionFullHeader } from '@component/section/section-full-header'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { Section } from '@component/section/section'
import { SectionFull } from '@component/section/section-full'
import { SectionPart } from '@component/section/section-part'
import { SplitContent } from '@component/content/split-content'
import { SectionDoor } from '@component/section/section-door'

const SolutionContainer: FC = observer((props) => {
  const scrollTo = (name: 'validator' | 'sto') => {
    const element = document.getElementById(name)
    if (element == null) return
    const headerOffset = 60
    const elementPosition = element?.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - headerOffset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }

  return (
    <Fragment>
      <div className="solution">
        <SectionDoor classes={['solution-door']}>
          <Fragment key="title">KDAC Enterprise Solutions</Fragment>
          <Fragment key="text">
            KDAC(케이닥)이 누적해온 블록체인 기술역량을 바탕으로 기업의 블록체인 사업에 필요한 솔루션을 제공합니다.
          </Fragment>
          <Fragment key="button">
            <button onClick={() => scrollTo('validator')}>Validator</button>
            <button onClick={() => scrollTo('sto')}>STO</button>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfs9hUa8DHIaJROi50ST4iRPJ-TgIm8YOW5bAnAsPUOb77cCw/viewform"
              target="_blank"
              rel="noreferrer"
            >
              문의하기
            </a>
          </Fragment>
        </SectionDoor>
        {/* validator section */}
        <SectionFullHeader id="validator" height={100}>
          <p>
            KDAC은 빌더들을 위한 블록체인 <span>밸리데이터 서비스</span>를 제공합니다.
          </p>
        </SectionFullHeader>
        <Section classes={['section-validator']}>
          <SectionFull>
            <SectionPart>
              <SplitContent imageClass="logo-staking" isImageRight={true}>
                <Fragment key="title">기업 가상자산의 스테이킹</Fragment>
                <Fragment key="text">
                  KDAC이 자체 운영하는 노드에서 콜드월렛 방식으로 운영되는 <br />
                  노드를 이용하며, 기업 환경에 맞게 다양한 부가서비스를 제공
                </Fragment>
              </SplitContent>
            </SectionPart>
          </SectionFull>
          <SectionFull>
            <SectionPart>
              <SplitContent imageClass="logo-validator">
                <Fragment key="title">
                  메인넷 빌더를 위한 <br />
                  밸리데이터 운영
                </Fragment>
                <Fragment key="text">
                  기업고객 시장에서 프로젝트 팀의 인지도를 상승시키고, <br />
                  금융 사이드의 Use-Case를 만들어낼 수 있으며, <br />
                  참여 기업의 보관 및 스테이킹 보상 등의 문제를 해결
                </Fragment>
              </SplitContent>
            </SectionPart>
          </SectionFull>
          <SectionFull>
            <SectionPart>
              <SplitContent imageClass="logo-ha" isImageRight={true}>
                <Fragment key="title">안정적인 서비스</Fragment>
                <Fragment key="text">
                  2년간 다수 네트워크를 100%의 가동률로 운영하고, <br />
                  콜드월렛 방식의 개인키 관리를 통해 스테이킹, 밸리데이터
                  <br />
                  등의 운영환경 안정성 극대화
                </Fragment>
              </SplitContent>
            </SectionPart>
          </SectionFull>
        </Section>
        <SectionFullHeader id="sto" height={100}>
          <p>
            KDAC은 STO 사업에 필요한 솔루션을 <span>토털 패키지</span>로 제공합니다.
          </p>
        </SectionFullHeader>
        <Section classes={['section-sto']}>
          <div className="section-sto">
            <div className="section-full">
              <div className="section-part row-column">
                <div className="section-content sto-slider">
                  <div className="slider-tit">
                    <p className="sub-logo"></p>
                    <p>청약 및 발행</p>
                    <p className="slider-txt">
                      실물 자산을 기반으로 자산을
                      <br />
                      청약하고 발행하는 기능을 제공합니다.
                    </p>
                  </div>

                  <Swiper
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      1000: {
                        slidesPerView: 1,
                      },
                      1280: {
                        slidesPerView: 3,
                      },
                    }}
                    slidesPerView={3}
                    spaceBetween={0}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="sto-mobile"
                  >
                    <SwiperSlide>
                      <img src="/static/images/solution/sto_m_img1.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/static/images/solution/sto_m_img2.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/static/images/solution/sto_m_img3.png" alt="" />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>

            <div className="section-full">
              <div className="section-part row-column">
                <div className="section-content sto-slider m-sto-slider">
                  <Swiper
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      1000: {
                        slidesPerView: 1,
                      },
                      1280: {
                        slidesPerView: 3,
                      },
                    }}
                    slidesPerView={3}
                    spaceBetween={0}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="sto-mobile2"
                  >
                    <SwiperSlide>
                      <img src="/static/images/solution/sto_m_img4.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/static/images/solution/sto_m_img5.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/static/images/solution/sto_m_img6.png" alt="" />
                    </SwiperSlide>
                  </Swiper>

                  <div className="slider-tit slider-tit3">
                    <p className="sub-logo"></p>
                    <p>투표 및 매각</p>
                    <p className="slider-txt">
                      자산 매각을 위한 소유자들의
                      <br />
                      투표 기능부터 정산 처리가 가능합니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-full">
              <div className="section-part row-column">
                <div className="section-content sto-slider">
                  <div className="slider-tit slider-tit2">
                    <p className="sub-logo"></p>
                    <p>유통 및 거래 내역</p>
                    <p className="slider-txt">
                      자산을 소유한 소유자들간의 매매가 가능하며,
                      <br />
                      거래 내역을 통해 거래 현황을 확인할 수 있습니다.
                    </p>
                  </div>

                  <Swiper
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      1000: {
                        slidesPerView: 1,
                      },
                      1280: {
                        slidesPerView: 3,
                      },
                    }}
                    slidesPerView={3}
                    spaceBetween={0}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="sto-mobile2"
                  >
                    <SwiperSlide>
                      <img src="/static/images/solution/sto_m_img7.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/static/images/solution/sto_m_img8.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/static/images/solution/sto_m_img9.png" alt="" />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>

            <div className="section-full padding-half">
              <div className="section-part row-column">
                <div className="section-content">
                  <p className="tit">STO 공급 사례</p>
                  <div className="tit-img-box section-row" style={{ margin: '0 0 60px 0' }}>
                    <div className="tit-txtbox2">
                      <p className="sub-tit">갤럭시아머니트리 주식회사</p>
                      <p className="txt" style={{ margin: '16px 0 0 0' }}>
                        갤럭시아머니트리는 종합생활금융 플랫폼 서비스 기업으로서,
                        <br />
                        앞선 기술력과 차별화된 서비스 경쟁력으로 전자 결제, O2O 비즈니스 및 머니트리
                        <br />
                        (Money Tree) 서비스를 통해 온ᆞ오프라인 결제 시장을 선도하고 있습니다.
                      </p>
                    </div>
                    <div className="sto-img7"></div>
                  </div>
                  <div className="tit-img-box">
                    <div className="sto-img8"></div>
                    <div style={{ margin: '28px 0 0 0' }}>
                      <p className="sub-tit">주식회사 아티피오</p>
                      <p className="txt" style={{ margin: '16px 0 0 0' }}>
                        아티피오는 예스24의 자회사로, 미술품 거래 사업을 담당하는 플랫폼 회사입니다.
                        <br />
                        국내외 인기 작가의 미술품 소유권을 여러 공유 지분으로 분할해 판매하는 서비스로, <br />
                        일반 대중도 부담없이 예술 작품을 구매할 수 있어 <br />
                        미술품 소유 대중화에 앞장서고 있습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </Fragment>
  )
})

export default SolutionContainer
