// necessary set
import React from 'react'
import { observer } from 'mobx-react'

import { useLocation } from 'react-router-dom'

// need content

// need tool or method

// Props type

// Faram type

// need style
import './board.scss'
import { getBoard } from './Board'

/**
 * @author cardo
 * @description
 * sto-user-front: BoardView
 * BoardView
 */
const BoardView: React.FC = observer((props) => {
  const location = useLocation()
  const splitUrl = location.pathname.split('/')
  const currentId = Number(splitUrl[splitUrl.length - 1])

  const getBoardData = () => {
    const board = getBoard(currentId)
    if (!board) {
      return <pre>등록된 글이 없습니다.</pre>
    }
    return (
      <React.Fragment key={board.id}>
        <p className="view-tit">{board.tit}</p>
        <div className="view-txt">
          <div className="view-inner-txt" dangerouslySetInnerHTML={{ __html: board.txt }}></div>
        </div>
        {board.createTime && (
          <div className="view-txt right">
            게시일 : <span>{board.createTime}</span>
          </div>
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="section-full b-black-bg">
        <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
          <div className="board-door">공지사항</div>
        </div>
      </div>

      <div className="section-part " style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
        <div className="noti-content">
          <div className="view-box">{getBoardData()}</div>
        </div>
      </div>
    </React.Fragment>
  )
})

export default BoardView
