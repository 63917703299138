// necessary set
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'

// need content
import App from '@src/App'

import '@style/style.scss'
// need tool or method
// import StoreProvider from '@provider/StoreProvider'

// need style
// import '@style/style.scss'
/**
 *
 * @author cardo
 * @description
 * cardo-home index
 * project 최상위 부분
 */
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    // <StoreProvider>
    <QueryClientProvider client={queryClient}>
        <App />
    </QueryClientProvider>,
    // </StoreProvider>,
)
