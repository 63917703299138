import React from 'react'
import { observer } from 'mobx-react'
import { ElementProps } from '@component/props.type'
import './split-content.scss'
import { extractChildNodeToRecord } from '@component/content/content.util'

type SplitContentProps = ElementProps & {
  logoClass?: string
  contentWidth?: number
  title?: React.ReactNode
  text?: React.ReactNode
  imageClass?: string
  isImageRight?: boolean
}

const getImageBox = (props: SplitContentProps, direction: 'l' | 'r', imageBox?: React.ReactNode) => {
  if (imageBox != null) {
    return imageBox
  }

  const defaultImageBox = (
    <div className="content-image-box">
      <div className={props.imageClass} />
    </div>
  )
  if (!props.isImageRight && direction === 'l') {
    return defaultImageBox
  }
  if (!!props.isImageRight && direction === 'r') {
    return defaultImageBox
  }
  return null
}

const getLeftImageBox = (props: SplitContentProps, imageBox?: React.ReactNode) => getImageBox(props, 'l', imageBox)
const getRightImageBox = (props: SplitContentProps, imageBox?: React.ReactNode) => getImageBox(props, 'r', imageBox)

export const SplitContent: React.FC<SplitContentProps> = observer((props) => {
  const classes = ['content-split']
  if (Array.isArray(props.classes)) {
    classes.push(...props.classes)
  }

  const childElements = {
    title: props.title,
    text: props.text,
    leftImageBox: getLeftImageBox(props),
    rightImageBox: getRightImageBox(props),
  } as Record<string, React.ReactNode>

  extractChildNodeToRecord(props.children, childElements)

  const contentWidth = props.contentWidth ?? 1280
  return (
    <div
      id={props.id}
      className={classes.join(' ')}
      style={{ ...props.style, '--sectionWidth': `${contentWidth}px` } as React.CSSProperties}
    >
      {getLeftImageBox(props, childElements.leftImageBox)}
      <div className="content-info">
        <p className="content-logo" />
        <p className="content-title">{childElements.title}</p>
        <p className="content-text">{childElements.text}</p>
      </div>
      {getRightImageBox(props, childElements.rightImageBox)}
    </div>
  )
})
