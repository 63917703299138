import React from 'react'
import { observer } from 'mobx-react'
import { ElementProps } from '../props.type'

export const SectionPart: React.FC<ElementProps> = observer((props) => {
  const classes = ['section-part']
  if (Array.isArray(props.classes)) {
    classes.push(...props.classes)
  }
  return (
    <div id={props.id} className={classes.join(' ')} style={props.style}>
      {props.children}
    </div>
  )
})
