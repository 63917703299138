// necessary set
import React from 'react'
import { observer } from 'mobx-react'

// import { useLocation, useNavigate } from 'react-router-dom'
// need content
import BoardTh from '@pages/board/BoardTh'
import BoardTd from '@pages/board/BoardTd'

// need tool or method
// Props type
// Faram type
// need style
import './board.scss'
import { boardList } from './Board'

/**
 * @author cardo
 * @description
 * sto-user-front: NoticeContainer
 * Notice Container
 */
const NoticeContainer: React.FC = observer((props) => {
  const getBoardList = () => {
    return boardList.map((board) => {
      let createTime = ''
      if (board.createTime != null) {
        const d = new Date(board.createTime)
        if (!isNaN(d.getTime())) {
          createTime = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d
            .getDate()
            .toString()
            .padStart(2, '0')}`
        }
      }
      return (
        <BoardTd
          titleTxt={[
            { text: board.type, width: 10, tag: 'black' },
            { text: board.tit, width: 70 },
            { text: createTime, width: 20 },
          ]}
          pageNumber={board.id}
        />
      )
    })
  }
  return (
    <React.Fragment>
      <div className="section-full b-black-bg">
        <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
          <div className="board-door">공지사항</div>
        </div>
      </div>

      <div className="section-part " style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
        <div className="noti-content">
          <div className="board-tab">
            <span className="active">공지사항</span>
          </div>

          <div className="tb-body">
            <BoardTh
              titleTxt={[
                { text: '구분', width: 10 },
                { text: '제목', width: 70 },
                { text: '게시일', width: 20 },
              ]}
            />
            {getBoardList()}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
})

export default NoticeContainer
