// necessary set
import React from 'react'
import { observer } from 'mobx-react'
import { getBoardNavigation } from '../board/Board'

// import { useLocation, useNavigate } from 'react-router-dom'
// need content
// need tool or method
// Props type
import { IPageProps } from '@common/PropsType'

// Faram type
// need style
import './custody.scss'
import { SectionFull } from '@component/section/section-full'
import { SectionPart } from '@component/section/section-part'

interface Props extends IPageProps {
  goTop: () => void
}

/**
 * @author cardo
 * @description
 * sto-user-front: MainContainer
 * Main Container
 */
const CustodyContainer: React.FC<Props> = observer((_props) => {
  return (
    <React.Fragment>
      <SectionFull classes={['custody-door']}>
        <SectionPart>
          <div className="custody-content door">
            <p className="tit">KDAC Custody Wallet</p>
            <p className="sub-tit">가장 안전하고 편리한 법인 전용 커스터디 솔루션</p>

            <p className="txt">KDAC의 커스터디 월렛은 Air-Gapped 기반으로 고객의 자산을 완벽히 안전하게 보관합니다.</p>

            <div className="btn-box">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfs9hUa8DHIaJROi50ST4iRPJ-TgIm8YOW5bAnAsPUOb77cCw/viewform"
                target="_blank"
                rel="noreferrer"
              >
                KDAC(케이닥) 문의하기
              </a>
            </div>
          </div>
        </SectionPart>
      </SectionFull>
      <SectionFull classes={['b-black-bg']}>
        <SectionPart>
          <div className="cardo-noti">
            <span className="noti-tit">공지사항</span>
            {getBoardNavigation()}
          </div>
        </SectionPart>
      </SectionFull>

      <SectionPart>
        <div className="custody-content center">
          <p className="sub-logo"></p>
          <p className="tit">지원 가능한 메인 네트워크</p>
          <div className="coin-box">
            <span className="btc">BTC</span>
            <span className="eth">ETH</span>
            <span className="klay">KLAY</span>
            <span className="matic">MATIC</span>
            <span className="sol">SOL</span>
            <span className="bnb">BNB</span>
            <span className="after-add">계속 추가</span>
          </div>
        </div>
      </SectionPart>
      <SectionFull classes={['b-bg']}>
        <SectionPart>
          <div className="custody-content content-split">
            <div className="custody-info">
              <p className="sub-logo"></p>
              <p className="tit">멀티시그/Air-Gap 콜드월렛</p>
              <p className="txt">
                온라인 네트워크와 완벽하게 분리된 환경에서
                <br /> N of M의 서명으로 구현되어 가상 자산을 안전하게 보관
              </p>
            </div>
            <div className="tit-img-box">
              <div className="boxArea">
                <div className="box">
                  <img src="/static/images/custody/bg_feature_1.png?v=2" alt="오프라인 생성 및 보관" />
                  <p>오프라인 생성 및 보관</p>
                </div>
                <div className="box">
                  <img src="/static/images/custody/bg_feature_2.png?v=3" alt="멀티시그 기술" />
                  <p>멀티시그 기술</p>
                </div>
                <div className="box">
                  <img src="/static/images/custody/bg_feature_3.png" alt="엄격한 절차" />
                  <p>엄격한 절차</p>
                </div>
                <div className="box">
                  <img src="/static/images/custody/bg_feature_4.png?v=1" alt="안전한 보관" />
                  <p>안전한 보관</p>
                </div>
              </div>
            </div>
          </div>
        </SectionPart>
      </SectionFull>

      <SectionFull>
        <SectionPart>
          <div className="custody-content content-split">
            <div className="tit-img-box">
              <div className="logo-soc"></div>
            </div>
            <div className="custody-info">
              <p className="sub-logo"></p>
              <p className="tit">
                업계 유일 <br />
                SOC1 유형2 인증
              </p>
              <p className="txt">
                글로벌 표준 재무보고서 신뢰성 인증을 취득하여
                <br />
                외감 대상 기업의 가상자산 보유 시 회계감사를 완벽하게 대응
              </p>
            </div>
          </div>
        </SectionPart>
      </SectionFull>

      <SectionFull classes={['b-bg']}>
        <SectionPart>
          <div className="custody-content content-split">
            <div className="custody-info">
              <div>
                <p className="sub-logo"></p>
                <p className="tit">비트코인 ETF 완벽 지원</p>
                <p className="txt" style={{ margin: '16px 0 0 0', color: '#4974B5' }}>
                  비트코인 네이티브 멀티시그 기반,
                </p>
                <p className="txt" style={{ color: '#4974B5' }}>
                  SOC1 유형2 인증으로 완벽한 서비스 제공
                </p>
              </div>
            </div>
            <div className="tit-img-box">
              <div className="cold-wallet"></div>
            </div>
          </div>
        </SectionPart>
      </SectionFull>

      <SectionFull classes={['authority-bg']}>
        <SectionPart>
          <div className="custody-content content-split">
            <div className="tit-img-box">
              <div className="authority"></div>
            </div>
            <div className="custody-info">
              <div>
                <p className="tit">권한 분리와 내부 통제</p>
                <p className="txt" style={{ margin: '16px 0 0 0' }}>
                  고객의 다중 승인 체계 및 출금 승인
                </p>
                <p className="txt">절차를 통해 더욱 안전한 관리체계</p>
              </div>
            </div>
          </div>
        </SectionPart>
      </SectionFull>

      <SectionFull>
        <SectionPart>
          <div className="custody-content" style={{ padding: '100px 0 80px' }}>
            <p className="sub-logo"></p>
            <p className="tit">Transferring Digital Asset</p>
            <p className="txt" style={{ marginTop: '10px' }}>
              KDAC의 디지털자산 출금은 엄격한 감시와 체계적인 보안절차를 거쳐 진행됩니다.
            </p>
            <div className="withdrawal-process-box">
              <div className="box">
                <img src="/static/images/custody/ico_feature_step_1.png" alt="" />
                <h1>1단계</h1>
                <h2>고객은 화이트리스트 등록 등 향후 출금을 위한 사전절차를 진행해야 합니다.</h2>
              </div>
              <img className="arrow" src="/static/images/custody/ico_arrow_right_blue.png" alt=">" />
              <div className="box">
                <img src="/static/images/custody/ico_feature_step_2.png" alt="" />
                <h1>2단계</h1>
                <h2>
                  고객의 출금 요청 시 안전을 위한 출금지연 단계가 존재하며, 사전에 합의된 일자에 출금이 진행됩니다.
                </h2>
              </div>
              <img className="arrow" src="/static/images/custody/ico_arrow_right_blue.png" alt=">" />
              <div className="box">
                <img src="/static/images/custody/ico_feature_step_3.png" alt="" />
                <h1>3단계</h1>
                <h2>고객의 신원을 확인하고 추가 인증을 포함한 최종 승인단계를 진행합니다.</h2>
              </div>
              <img className="arrow" src="/static/images/custody/ico_arrow_right_blue.png" alt=">" />
              <div className="box">
                <img src="/static/images/custody/ico_feature_step_4.png" alt="" />
                <h1>4단계</h1>
                <h2>콜드 월렛에 보관된 고객자산을 멀티 시그 방식을 통해 안전하게 출금 합니다.</h2>
              </div>
            </div>
          </div>
        </SectionPart>
      </SectionFull>
    </React.Fragment>
  )
})

export default CustodyContainer
