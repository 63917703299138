// necessary set
import React, { Fragment } from 'react'
import { observer } from 'mobx-react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// need content
// need tool or method
// Props type
// Faram type
// need style
import './company.scss'
import { getBoardNavigation } from '../board/Board'
import { SectionDoor } from '@component/section/section-door'
import { SectionFull } from '@component/section/section-full'
import { SectionPart } from '@component/section/section-part'
import { SectionContent } from '@component/section/section-content'
// import { ExtLink } from '@component/ext-link'

/**
 * @author cardo
 * @description
 * sto-user-front: CompanyContainer
 * Company Container
 */
const CompanyContainer: React.FC = observer((props) => {
  return (
    <React.Fragment>
      <div className="company">
        <SectionDoor classes={['company-door']}>
          <Fragment key="title">KDAC(Korea Digital Asset Custody)</Fragment>
          <Fragment key="text">
            KDAC과 카르도가 하나가 되었습니다.
            <br />
            더욱더 신뢰받는 서비스로 보답하겠습니다.
          </Fragment>
        </SectionDoor>
      </div>

      <div className="section-full b-black-border-bg">
        <div className="section-part">
          <div className="cardo-noti">
            <span className="noti-tit">공지사항</span>
            {getBoardNavigation()}
          </div>
        </div>
      </div>

      <SectionFull>
        <SectionPart style={{ '--sectionWidth': '1280px' }}>
          <SectionContent>
            <div className="company-img fiu"></div>
            <div className="title">가상자산사업자 신고 수리 완료</div>
            <div className="text">
              주식회사 KDAC은 금융위원회 산하
              <br />
              금융정보분석원 관련법에 따라
              <br />
              가상자산사업자로 신고 및 수리 완료된 업체입니다.
            </div>
            {/*<ExtLink url="https://www.kofiu.go.kr/kor/main.do">조회하기</ExtLink>*/}
          </SectionContent>
          <SectionContent>
            <div className="company-img isms"></div>
            <div className="title">ISMS 인증 완료</div>
            <div className="text">
              한국인터넷진흥원(KISA)의
              <br />
              정보보호관리체계 인증을 받아
              <br />
              고객의 정보와 자산을 안전하게 보관하고 있습니다.
              <br />
            </div>
            {/*<ExtLink url="https://isms.kisa.or.kr/main/ispims/issue/?certificationMode=list&crtfYear=&pageFlag=ISMS-P&searchCondition=2&searchKeyword=%ED%95%9C%EA%B5%AD%EB%94%94%EC%A7%80%ED%84%B8%EC%9E%90%EC%82%B0%EC%88%98%ED%83%81">*/}
            {/*  조회하기*/}
            {/*</ExtLink>*/}
          </SectionContent>
          <SectionContent>
            <div className="company-img soc"></div>
            <div className="title">SOC 인증 완료</div>
            <div className="text">
              미국공인회계사회(AICPA) 및
              <br />
              국제감사인증기준위원회(IAASB)에서 제정한
              <br />
              인증기준을 충족하였습니다.
            </div>
            {/*<ExtLink url="https://www.kofiu.go.kr/kor/main.do">조회하기</ExtLink>*/}
          </SectionContent>
        </SectionPart>
      </SectionFull>

      <div className="section-part row-column">
        <div className="section-content" style={{ padding: '60px 0' }}>
          <p className="his-tit">
            <span>연혁</span>
          </p>
          <div className="history-header">
            <span className="logo-kdac"></span>
            <span className="logo-cardo"></span>
          </div>
          <div className="history">
            <span className="start"></span>
            <div className="history-box">
              <div className="history-year">
                <span>2021</span>
              </div>
              <div className="his-info left">
                <ul className="txt">
                  <li>신한은행 / 한국정보인증 투자유치</li>
                  <li>가상자산사업자 신고 수리 완료</li>
                </ul>
              </div>
              <div className="his-info">
                <ul className="txt">
                  <li>NH농협은행 등 합작 법인 출자</li>
                  <li>ISMS 인증</li>
                </ul>
              </div>
            </div>
            <div className="history-box">
              <div className="history-year">
                <span>2022</span>
              </div>
              <div className="his-info left">
                <ul className="txt">
                  <li>법인 원화거래 시범사업</li>
                  <li>밸리데이터 / 스테이킹 서비스 시작</li>
                </ul>
              </div>
              <div className="his-info">
                <ul className="txt">
                  <li>가상자산사업자 신고 수리 완료</li>
                  <li>국내 최초 NFT 수탁</li>
                  <li>카르도 커스터디 서비스 시작</li>
                </ul>
              </div>
            </div>
            <div className="history-box">
              <div className="history-year">
                <span>2023</span>
              </div>
              <div className="his-info left">
                <ul className="txt">
                  <li>SOC1 유형 2 인증 취득</li>
                </ul>
              </div>
              <div className="his-info">
                <ul className="txt">
                  <li>조각투자 플랫폼(STO) 공급 협약 체결</li>
                </ul>
              </div>
            </div>
            <div className="history-end">
              <div className="text-box">
                <span className="txt">2024. 7 합병 KDAC 출범</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SectionPart>
        <SectionContent>
          <div className="subtit-box">
            <p className="tit tit2">주요 주주</p>
          </div>
          <div className="logo-list company-logo holder-company">
            <span className="logo logo-nh"></span>
            <span className="logo logo-sh"></span>
          </div>
          <div className="logo-list company-logo holder-company">
            {/*<span className="logo logo-hexlant"></span>*/}
            <span className="logo logo-galaxia"></span>
            <span className="logo logo-korbit"></span>
            <span className="logo logo-fair2lab"></span>
          </div>
          <div className="logo-list company-logo holder-company">
            {/*<span className="logo logo-galaxia"></span>*/}
            <span className="logo logo-kica"></span>
            {/*<br className="mobile-br" />*/}
            <span className="logo logo-kicc"></span>
            <span className="logo logo-aton"></span>
          </div>
        </SectionContent>
      </SectionPart>

      <SectionPart>
        <SectionContent>
          <div className="subtit-box">
            <p className="tit tit2">
              KDAC <span>오시는 길</span>
            </p>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.9567303837316!2d127.01933547651639!3d37.5089386720536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca300446c72f1%3A0x43a595e626d84a60!2z7ZWc6rWt65SU7KeA7YS47J6Q7IKw7IiY7YOB!5e0!3m2!1sko!2skr!4v1719388303822!5m2!1sko!2skr"
            className="map"
            title="주소"
          ></iframe>
        </SectionContent>
      </SectionPart>
    </React.Fragment>
  )
})

export default CompanyContainer
